const getYearAndHalfs = (partialYearInMonths: number, inYearsRoundedDown: number): string => {
  if (partialYearInMonths < 1) {
    return `${inYearsRoundedDown}+`;
  }
  if (partialYearInMonths < 5) {
    return `${inYearsRoundedDown}+`;
  }
  if (partialYearInMonths > 5) {
    return `${inYearsRoundedDown}.5+`;
  }
  return `${inYearsRoundedDown}.5`;
};

/**
 * Loose, approximate time difference 💀
 */
export const getTimeDifference = (
  /** date in the format that can be passed to the Date() constructor  */
  fromDate: string,
  /** date in the format that can be passed to the Date() constructor  */
  toDate?: string
) => {
  const from = new Date(fromDate).getTime();
  const to = toDate ? new Date(toDate).getTime() : Date.now();

  if (Number.isNaN(from)) {
    throw new Error('fromDate is an invalid date');
  } else if (Number.isNaN(to)) {
    throw new Error('toDate is an invalid date');
  } else if (to < from) {
    throw new Error('fromDate must be earlier than toDate');
  }

  const inMilliseconds = to - from;
  const inSeconds = inMilliseconds / 1000;
  const inMinutes = inSeconds / 60;
  const inHours = inMinutes / 60;
  const inDays = inHours / 24;
  const inYears = inDays / 365;
  const inYearsRoundedDown = Math.floor(inYears);
  const partialYearInMonths = Math.floor((inYears - inYearsRoundedDown) * 12);
  const inYearsAndMonths = `${inYearsRoundedDown} years ${partialYearInMonths} months`;
  const inMonths = inYearsRoundedDown * 12 + partialYearInMonths;
  const inYearAndHalfs = getYearAndHalfs(partialYearInMonths, inYearsRoundedDown);

  return {
    inMilliseconds,
    inSeconds,
    inMinutes,
    inHours,
    inDays,
    inYears,
    inMonths,
    inYearsAndMonths,
    inYearAndHalfs: `${inYearAndHalfs} years`,
  };
};
