// can't use aliases here since vercel functions won't work
import { getTimeDifference } from '../utils/datetime';

export interface ProfileDetails {
  href: string;
  name: string;
  title: string;
  username: string;
  handle?: string;
}

export enum SocialProfile {
  Twitter = 'twitter',
  GitHub = 'github',
  LinkedIn = 'linkedin',
}

type SocialDetails = {
  [k in SocialProfile]: ProfileDetails;
};

interface Me {
  name: {
    usual: string;
    first: string;
    full: string;
    last: string;
    middle: string;
  };
  social: SocialDetails;
  professionalExperience: ReturnType<typeof getTimeDifference>;
}

export const me: Me = {
  name: {
    usual: 'Aditi Mohanty',
    first: 'Aditi',
    full: 'Aditi Anomita Mohanty',
    last: 'Mohanty',
    middle: 'Anomita',
  },
  social: {
    twitter: {
      href: 'https://twitter.com/rheaditi',
      name: 'Twitter',
      title: '@rheaditi on Twitter',
      username: 'rheaditi',
      handle: '@rheaditi',
    },
    github: {
      href: 'http://github.com/rheaditi',
      name: 'Github',
      title: '@rheaditi on GitHub',
      username: 'rheaditi',
    },
    linkedin: {
      href: 'https://www.linkedin.com/in/aditimohanty',
      name: 'LinkedIn',
      title: 'Aditi Mohanty on LinkedIn',
      username: 'aditimohanty',
    },
  },
  professionalExperience: getTimeDifference('2016-07-04'),
};

export const site = {
  title: 'Aditi Mohanty - Front-end Engineer - Bangalore, India',
  description:
    'Aditi Mohanty (@rheaditi), a female human from Bangalore, India - ❤s Javascript, video-games & music. Doing front-end stuff at Atlassian.',
  url: {
    base: 'https://aditimohanty.com/',
  },
  keywords: [
    // names
    ...Object.values(me.name),
    'rheaditi',
    'rhea aditi',
    'rhea aditi mohanty',

    // profession
    'developer',
    'web developer',
    'front end developer',
    'software engineer',
    'senior engineer',
    'senior software engineer',
    'UI',
    'UX',
    'User Interface',
    'User Experience',
    'Web',
    'Designer',
    'Developer',
    'Engineer',

    // places
    'Bangalore',
    'Bengaluru',
    'India',
    'Karnataka',
    'Bhubaneshwar',
    'Odisha',
    'Orissa',

    // education
    'PESIT',
    'PES Institute of Technology',
    'PESSE',
    'PESU',
    'Computer Science',
    'Computer Science & Engineering',
  ],
  builtYear: 2020,
  footer: {
    links: [
      {
        name: 'twitter',
        label: 'Twitter',
        title: '@rheaditi on Twitter',
        href: 'https://twitter.com/rheaditi',
        rel: 'nofollow',
      },
      {
        name: 'github',
        label: 'GitHub',
        title: '@rheaditi on GitHub',
        href: 'http://github.com/rheaditi',
        rel: 'nofollow',
      },
      {
        name: 'sitemap',
        label: 'Sitemap',
        title: 'Sitemap',
        href: '/sitemap.xml',
      },
    ],
  },
};

/**
 * JSON Resume format
 * @see https://jsonresume.org/schema/
 * @version 0.0.0
 */
export const resume = {
  basics: {
    name: me.name.full,
    label: 'Front-end Engineer',
    website: site.url,
    // TODO: Summary
    summary: `A software engineer with ${me.professionalExperience.inYearAndHalfs} of experience in building consumer & enterprise-scale web-applications, specializing in front-end technologies.`,
    location: {
      city: 'Bangalore',
      countryCode: 'IN',
      region: 'Karnataka',
    },
    profile: Object.values(me.social).map(({ name, href, username }) => ({
      network: name,
      url: href,
      username,
    })),
    experience: me.professionalExperience.inYearsAndMonths,
  },
  references: [
    {
      name: 'Ananda Raj P',
      href: 'https://www.linkedin.com/in/rajanand02/',
      reference: 'Ex-Engineering Manager, ClearTax',
    },
    {
      name: 'Ramesh Perumalsamy',
      href: 'https://www.linkedin.com/in/rameshpy/',
      reference: 'Ex-Director of Engineering, ClearTax',
    },
    {
      name: 'Sunitha Chandrasekaran',
      reference: 'Manager, Sabre Corporation',
      href: 'https://www.linkedin.com/in/sunithachandra/',
    },
  ],
};
